import React from 'react';

import AnchorButton from '../Anchor/AnchorButton';
import PatreonIcon from '../icons/PatreonIcon';

export const getPatreonLoginURL = (): string => {
  const params = new URLSearchParams({
    response_type: 'code',
    client_id: '7c0AyRN20yMxRgPdm2ZeCMhGRYswLN5T8GXeyhsYxgHouSNQhubOuD3NhTAUOvvp',
    redirect_uri: 'https://www.jackiecannon.co.uk/login/redirect',
    scope: 'identity identity[email] identity.memberships campaigns campaigns.members',
    // scope: 'identity campaigns',
    state: 'login',
  });

  return 'https://www.patreon.com/oauth2/authorize?' + params.toString();
};

interface Props {
  url?: string;
  display: string;
  color?: string;
  text?: string;
}

export const PatreonLoginBtn = ({ display, url, color, text }: Props) => {
  return (
    <AnchorButton href={url || getPatreonLoginURL()} color={color || 'var(--patreon)'} text={text || 'white'} type="login" page="login" style={{ padding: '1em 1.5em', opacity: '1' }}>
      <PatreonIcon style={{ fontSize: '1.3em', verticalAlign: 'middle', whiteSpace: 'nowrap' }} />
      &nbsp; {display}
    </AnchorButton>
  );
};
