import { makeStyles } from '../../utils/makeStyles';
import { fromValues } from '../../utils/styleUtils';

export interface GridStyleValues {
  maxCols: number;
}

const large = {
  width: 'calc(var(--tile-size) * 2)',
  height: 'calc(var(--tile-size) * 2)',
  'grid-column-end': 'span 2',
  'grid-row-end': 'span 2',
};

const useStyles = makeStyles(() => ({
  container: {
    '--max-cols': fromValues('maxCols', 5),
    '--tile-size': 'calc(min(100vw, var(--maxwidth)) / min(var(--num-cols), var(--max-cols)))',
    width: '100vw',
    'max-width': 'var(--maxwidth)',
    margin: '0 auto',
    display: 'grid',
    'grid-template-columns': 'repeat(auto-fill, var(--tile-size))',
    gap: '0',
    'grid-auto-flow': 'dense',
    'list-style': 'none',
    padding: '0',
  },

  mixed: {},

  '@media only screen and (max-width: 599px)': {
    mixed: {
      '& > *:nth-child(5n+1)': {
        ...large,
      },
    },
  },

  '@media only screen and (min-width: 600px) and (max-width: 799px)': {
    mixed: {
      '& > *:nth-child(7n+1)': {
        ...large,
      },
    },
  },

  '@media only screen and (min-width: 800px) and (max-width: 1199px)': {
    mixed: {
      '& > *:nth-child(10n+1)': {
        ...large,
      },
    },
  },

  '@media only screen and (min-width: 1200px)': {
    mixed: {
      '& > *:nth-child(10n+1)': {
        ...large,
      },
    },
  },
}));

export default useStyles;
