import React, { useContext } from 'react';
import LazyLoad from 'react-lazy-load';

import DataContext from '../../data/DataContext';
import { SmpDataAlbum } from '../../types/SmpData';
import { displayDate } from '../../utils/dateUtils';
import { getAlbumRoute } from '../../utils/routes';
import { Link } from '../Link/Link';
import Locked from '../Locked/Locked';

import useStyles from './AlbumTile.styles';
import TileProps from './TileProps';

const AlbumTile = ({ item: itemRaw, imgProp, pathBase, isLocked, route }: TileProps) => {
  const item = itemRaw as SmpDataAlbum;
  const classes = useStyles();
  const { data } = useContext(DataContext);

  const showDates = !data || !data.token_info || data.token_info.showDates;

  return (
    <Link to={route || getAlbumRoute(item)} className={classes.root}>
      <LazyLoad debounce={false} offsetVertical={500} className={classes.lazyLoad}>
        <img alt="" src={pathBase + item[imgProp]} />
      </LazyLoad>
      <div className={classes.albumInfo}>
        <div className={classes.albumInfoContent}>
          <div className={classes.name}>{item.title}</div>
          {showDates && item.shootDate && <div className={classes.date}>{displayDate(item.shootDate)}</div>}
          {!showDates && item.model_instagram && <div className={classes.igHandle}>@{item.model_instagram}</div>}
        </div>
      </div>
      {isLocked && <Locked size="sm" className={classes.locked} />}
    </Link>
  );
};

export default AlbumTile;
