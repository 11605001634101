import { makeStyles } from '../../utils/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    margin: '0',
    cursor: 'pointer',
    width: 'var(--tile-size)',
    height: 'var(--tile-size)',
    overflow: 'hidden',

    '& img': {
      width: '100%',
    },

    '&:hover $albumInfo': {
      bottom: 0,
    },
    '&:hover $locked': {
      opacity: 0.5,
    },
  },
  lazyLoad: {
    display: 'flex',
  },
  albumInfo: {
    position: 'absolute',
    bottom: '-3.5em', // box height + gradient size + spacing
    left: 0,
    right: 0,
    height: '2.5em',
    backgroundColor: 'rgba(17, 17, 17, 0.4)',
    boxShadow: '0 0 0.75em 0.75em rgba(17, 17, 17, 0.4)',
    color: 'rgba(255, 255, 255, 1)',
    transition: 'bottom 0.5s',
  },
  albumInfoContent: {
    position: 'absolute',
    top: '-0.5em',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  name: {
    fontSize: '1.2em',
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  date: {
    fontSize: '0.8em',
    opacity: 0.8,
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  igHandle: {
    fontSize: '0.8em',
    opacity: 0.6,
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'clip',
  },

  locked: {
    opacity: 0,
    transition: 'opacity 0.5s',
    top: '45%',
  },

  '@media (hover: none)': {
    albumInfo: {
      bottom: 0,
    },
    locked: {
      opacity: 0.25,
    },
  },
}));

export default useStyles;
