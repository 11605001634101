import React, { memo, useContext } from 'react';
import { makeStyles } from '../../utils/makeStyles';
import { Warning as WarningIcon } from '@material-ui/icons';
import DataContext from '../../data/DataContext';
import { removeSavedToken } from '../../services/patreon';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    placeItems: 'center',
    placeContent: 'center',
    backgroundColor: 'rgba(var(--primary-rgb), 0.1)',
    margin: '0 auto',
    padding: '1em',
    userSelect: 'none',

    '& > span': {
      margin: '0 0.3em',
    },
    '& > svg': {
      fontSize: '1.5em',
    },
  },
  links: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: '0.5em',

    '& a': {
      textDecoration: 'underline',
    },
  },
}));

interface Props {}

const FailedLabel = (_props: Props) => {
  const classes = useStyles();

  const { data, isFailed } = useContext(DataContext);

  const hasData = data?.albums?.length > 0;

  const resetSavedToken = () => {
    removeSavedToken();
  };

  return (
    <>
      {!hasData && isFailed && (
        <>
          <div className={classes.root}>
            <WarningIcon />
            <span>
              <b>Oh no!</b> It looks like you don't have access, or it has expired.
            </span>
          </div>
          <div className={classes.links}>
            <a href={window.location.pathname} onClick={() => resetSavedToken()}>
              Click here to go to the public site
            </a>
            <a href="/login" onClick={() => resetSavedToken()}>
              Or click here to login
            </a>
          </div>
        </>
      )}
    </>
  );
};

export default memo(FailedLabel);
