import { useLocation } from 'react-router-dom';

export interface QueryParams {
  showAll: boolean;
  download: string;
  dark: boolean;
  light: boolean;
}

const processValue = (input) => {
  try {
    return JSON.parse(input);
  } catch (e) {
    return input;
  }
};

const useQuery = (): QueryParams => {
  const query = new URLSearchParams(useLocation().search);
  const result = {};
  for (const [key, value] of query.entries() as any) {
    result[key] = processValue(value);
  }
  return result as QueryParams;
};

export default useQuery;
