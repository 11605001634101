import React from 'react';

import { makeStyles } from '../../utils/makeStyles';

import { clx, fromValues } from '../../utils/styleUtils';
import Anchor, { AnchorProps } from './Anchor';

const useStyles = makeStyles(() => ({
  button: {
    display: 'inline-block',
    margin: '0.1em',
    backgroundColor: fromValues('color', '#0074D9'),
    color: fromValues('text', 'white'),
    padding: '0.5em',
    userSelect: 'none',

    '&:hover, &:active, &:visited, &:focus': {
      backgroundColor: fromValues('color', '#0074D9'),
      color: fromValues('text', 'white'),
      opacity: 0.75,
    },
  },
}));

interface Props extends AnchorProps {
  color?: string;
  text?: string;
}

const AnchorButton = (props: Props) => {
  const { className, color, text, children, ...other } = props;
  const classes = useStyles({ color, text });

  return (
    <Anchor className={clx(classes.button, className)} {...other}>
      {children}
    </Anchor>
  );
};

export default AnchorButton;
