import portrait1 from './images/portrait-1.jpg';
import portrait2 from './images/portrait-2.jpg';
import portrait3 from './images/portrait-3.jpg';
// import swimwear1 from './images/swimwear-1.jpg';
// import swimwear2 from './images/swimwear-2.jpg';
// import swimwear3 from './images/swimwear-3.jpg';
import lingerie1 from './images/lingerie-1.jpg';
import lingerie2 from './images/lingerie-2.jpg';
import lingerie3 from './images/lingerie-3.jpg';
import openShirt1 from './images/openShirt-1.jpg';
import openShirt2 from './images/openShirt-2.jpg';
import openShirt3 from './images/openShirt-3.jpg';
import sheer1 from './images/sheer-1.jpg';
import sheer2 from './images/sheer-2.jpg';
import sheer3 from './images/sheer-3.jpg';
import implied1 from './images/implied-1.jpg';
import implied2 from './images/implied-2.jpg';
import implied3 from './images/implied-3.jpg';
import artNude1 from './images/artNude-1.jpg';
import artNude2 from './images/artNude-2.jpg';
import artNude3 from './images/artNude-3.jpg';
import bodyPaint1 from './images/bodyPaint-1.jpg';
import bodyPaint2 from './images/bodyPaint-2.jpg';
import bodyPaint3 from './images/bodyPaint-3.jpg';

const levelImages = {
  portrait: [portrait1, portrait2, portrait3],
  // swimwear: [swimwear1, swimwear2, swimwear3],
  lingerie: [lingerie1, lingerie2, lingerie3],
  openShirt: [openShirt1, openShirt2, openShirt3],
  sheer: [sheer1, sheer2, sheer3],
  implied: [implied1, implied2, implied3],
  artNude: [artNude1, artNude2, artNude3],
  bodyPaint: [bodyPaint1, bodyPaint2, bodyPaint3],
};

export default levelImages;
