import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { useTitle } from '../../components/useTitle/useTitle';
import DataContext from '../../data/DataContext';
import { removeSavedToken } from '../../services/patreon';

import useStyles from './styles';

const AccountPage = () => {
  const { data, reset } = useContext(DataContext);
  const classes = useStyles();
  useTitle('Account');

  const hasToken = !!data?.token_info;
  const account = hasToken ? data.token_info.account : null;

  const logout = () => {
    removeSavedToken();
    reset();
  };

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Account</h2>

      <div className={classes.table}>
        <div>
          <div>Name</div>
          <div>{account.name}</div>
        </div>

        <div>
          <div>Email</div>
          <div>{account.email}</div>
        </div>

        <div>
          <div>Tier</div>
          <div>{account.tierDisplay}</div>
        </div>
      </div>

      <Link to="/" onClick={logout} className={classes.button}>
        Log Out
      </Link>
    </div>
  );
};

export default AccountPage;
