import React, { useContext } from 'react';
import Grid, { TILE_TYPE } from '../../components/Grid/Grid';
import { useTitle } from '../../components/useTitle/useTitle';
import DataContext from '../../data/DataContext';
import useQuery from '../../utils/useQuery';
// import data from '../../data/dummyData';

import useStyles from './styles';
import TokenLabel from './TokenLabel';

const HomePage = () => {
  const classes = useStyles();
  useTitle('');
  const { getFilteredAlbums, getUrlBase } = useContext(DataContext);
  const { showAll } = useQuery();

  const albums = getFilteredAlbums(!showAll, true);

  return (
    <div className={classes.root}>
      <TokenLabel />
      <Grid data={albums} imgProp="thumb_src" lockedProp="blurred" pathBase={getUrlBase()} tileType={TILE_TYPE.ALBUM} />
    </div>
  );
};

export default HomePage;
