import { makeStyles } from '../../utils/makeStyles';

const useStyles = makeStyles(() => ({
  root: {},
  content: {},
  main: {
    width: 'var(--maxwidth)',
    maxWidth: '100vw',
    margin: '0 auto',
  },
}));

export default useStyles;
