import { makeStyles } from '../../utils/makeStyles';
import { FloatingPhotoConfig } from './interfaces';

const useStyles = makeStyles<{}, FloatingPhotoConfig>(() => ({
  root: {
    display: 'flex',
    position: 'absolute',
    left: ({ pos: [pos], starting: [starting], isLoaded }) => (isLoaded ? `${pos}%` : `${pos + starting}%`),
    top: ({ pos: [_1, pos], starting: [_2, starting], isLoaded }) => (isLoaded ? `${pos}%` : `${pos + starting}%`),
    width: '24%',
    backgroundColor: '#d9d9d9',
    padding: '0.75% 0.75%',
    boxShadow: '0px 0px 1px 1px rgba(0, 0, 0, 0.1), 1px 1px 2.5px 2.5px rgba(0, 0, 0, 0.1), 2px 2px 4px 4px rgba(0, 0, 0, 0.1)',
    transformOrigin: '50% 65%',
    transform: ({ rotation, isLoaded }) => `translate(-50%, -50%) rotate(${isLoaded ? rotation : -30}deg)`,
    transition: 'left 1s, top 1s, transform 1s',

    '&:after': {
      content: '""',
      position: 'absolute',
      inset: '0',
      opacity: '0.15',
      background: 'radial-gradient(circle at 50% -50%, white 0, transparent 100%)',
    },

    '&:hover, &:active': {
      // transform: ({ pos: [pos], rotation }) => `translate(-50%, -50%) rotate(${(Math.sign(rotation) || 1) * -2}deg)`,
      transform: ({ pos: [pos], rotation }) => `translate(-50%, -50%) rotate(${rotation / 2}deg)`,
      left: ({ pos: [pos], starting: [starting] }) => `${pos + Math.sign(starting) * -0.5}%`,
      top: ({ pos: [_1, pos], starting: [_2, starting] }) => `${pos + Math.sign(starting) * -0.5}%`,
    },
  },
  photo: {
    width: '100%',
    // margin: '10%',
  },
  grubby: {
    position: 'absolute',
    inset: '0',
    width: '100%',
    height: '100%',
    opacity: '0.25',
    mixBlendMode: 'screen',
  },
  overlay: {
    position: 'absolute',
    inset: '0',
    width: '100%',
    height: '100%',
    opacity: '0.75',
  },
}));

export default useStyles;
