export enum DarkModeSetting {
  DEFAULT = 0,
  LIGHT = 1,
  DARK = 2,
}

const darkModeManager = {
  current: DarkModeSetting.DEFAULT,
  // current: DarkModeSetting.LIGHT,
  // current: DarkModeSetting.DARK,

  // current: [DarkModeSetting.LIGHT, DarkModeSetting.DARK][
  //   Math.floor(Math.random() * 2)
  // ],

  isDarkMode() {
    if (this.current === DarkModeSetting.DEFAULT) {
      return window.matchMedia('(prefers-color-scheme: dark)');
    }
    return this.current === DarkModeSetting.DARK;
  },

  setCurrent(forceDark: boolean, forceLight: boolean) {
    if (forceDark === true) {
      this.current = DarkModeSetting.DARK;
    }
    if (forceLight === true) {
      this.current = DarkModeSetting.LIGHT;
    }
  },

  setBodyClass() {
    if (this.current !== DarkModeSetting.DEFAULT) {
      const el = document.body;
      const newClass = this.current === DarkModeSetting.DARK ? 'force-dark' : 'force-light';
      if (!el.classList.contains(newClass)) {
        el.classList.add(newClass);
      }
    }
  },
};

export default darkModeManager;
