import { useEffect } from 'react';
import darkModeManager from '../../services/darkModeManager';
import useQuery from '../../utils/useQuery';

const ModeSetter = () => {
  const { dark, light } = useQuery();

  useEffect(() => {
    darkModeManager.setCurrent(dark, light);
    darkModeManager.setBodyClass();
  }, [dark, light]);

  return null;
};

export default ModeSetter;
