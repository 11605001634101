// The contents of this file should be the same in both finsbury-puck-cms and photo-portfolio

export const FILES_LOCAL = 'http://localhost:4000/files/';
export const FILES_PREVIEW = 'https://finsburypuck.com/files/';
export const FILES_LIVE = 'https://cannon-cms.s3.eu-west-2.amazonaws.com/';

export const getFilesUrl = (): string => (window.location.host.includes('localhost') ? FILES_LOCAL : FILES_PREVIEW);

// Simple file data format
// Used by portfolio - public and safe

export interface SmpData {
  base_local: string;
  base_preview: string;
  base_live: string;
  token_info?: SmpDataTokenInfo;
  links: SmpDataLink[];
  references: SmpDataReference[];
  albums: SmpDataAlbum[];
  info?: SmpDataInfo;
}

export interface SmpDataTokenInfo {
  tag: string;
  label: string;
  showLabel: boolean;
  showExclusive: boolean;
  showUpselling: boolean;
  showDates: boolean;
  account?: SmpDataTokenInfoAccount;
}

export interface SmpDataTokenInfoAccount {
  name: string;
  email: string;
  tier: string;
  tierDisplay: string;
}

export interface SmpDataLink {
  id: string;
  enabled: boolean;
  text: string;
  url: string;
  logo: string;
}
export interface SmpDataReference {
  id: string;
  enabled: boolean;
  text: string;
  model_name: string;
  model_url: string;
  img_album: string;
  img_photo: string;
  img_src: string;
}
export interface SmpDataAlbum {
  id: string;
  name: string;
  title: string;
  description: string;
  enabled: boolean;
  blurred: boolean;
  exclusive: boolean;
  tags: string[];
  shootDate: string; // date
  postDate: string; // date
  model_name: string;
  model_instagram: string;
  model_email: string;
  model_website: string; // url
  model_website_name: string; // what to label the website
  thumb_id: string; // tbc
  thumb_x: number;
  thumb_y: number;
  thumb_size: number;
  thumb_src: string;
  photos: SmpDataPhoto[];
}
export interface SmpDataPhoto {
  id: string;
  enabled: boolean;
  blurred: boolean;
  exclusive: boolean;
  originalName: string;
  name: string;
  isNSFW: boolean;
  thumb_x: number;
  thumb_y: number;
  thumb_size: number;
  src_full: string; // url
  src_thumb: string; // url
}

export interface SmpDataInfo {
  exclusiveAlbums: number;
  exclusivePhotos: number;
  photosInExclusiveAlbums: number;
  exclusivePhotosInPublicAlbums: number;
}

export const getEmptySmpData = (): SmpData => ({
  base_local: FILES_LOCAL,
  base_preview: FILES_PREVIEW,
  base_live: FILES_LIVE,
  token_info: undefined,
  links: [],
  references: [],
  albums: [],
});

export const getEmptySmpLink = (id = ''): SmpDataLink => ({
  id,
  enabled: true,
  text: '',
  url: '',
  logo: 'link',
});
export const getEmptySmpReference = (id = ''): SmpDataReference => ({
  id,
  enabled: true,
  text: '',
  model_name: '',
  model_url: '',
  img_album: '',
  img_photo: '',
  img_src: '',
});
export const getEmptySmpAlbum = (id = ''): SmpDataAlbum => ({
  id,
  name: '',
  title: '',
  description: '',
  enabled: false,
  blurred: false,
  exclusive: false,
  tags: [],
  shootDate: '',
  postDate: '',
  model_name: '',
  model_instagram: '',
  model_email: '',
  model_website: '',
  model_website_name: '',
  thumb_id: '',
  thumb_x: 0,
  thumb_y: 0,
  thumb_size: 1,
  thumb_src: '',
  photos: [],
});
export const getEmptySmpPhoto = (id = ''): SmpDataPhoto => ({
  id,
  enabled: true,
  blurred: false,
  exclusive: false,
  isNSFW: false,
  thumb_x: 0,
  thumb_y: 0.126,
  thumb_size: 1,
  name: '',
  originalName: '',
  src_full: '',
  src_thumb: '',
});

export const cloneSmpData = (data: SmpData): SmpData => ({
  ...data,
  links: cloneSmpDataLinks(data.links),
  references: cloneSmpDataReferences(data.references),
  albums: cloneSmpDataAlbums(data.albums),
});

export const cloneSmpDataLinks = (links: SmpDataLink[]): SmpDataLink[] => links.map(cloneDataLink);
export const cloneDataLink = (link: SmpDataLink): SmpDataLink => ({ ...link });

export const cloneSmpDataReferences = (references: SmpDataReference[]): SmpDataReference[] => references.map(cloneSmpDataReference);
export const cloneSmpDataReference = (reference: SmpDataReference): SmpDataReference => ({ ...reference });

export const cloneSmpDataAlbums = (albums: SmpDataAlbum[]): SmpDataAlbum[] => albums.map(cloneSmpDataAlbum);
export const cloneSmpDataAlbum = (album: SmpDataAlbum): SmpDataAlbum => ({
  ...album,
  photos: cloneSmpDataPhotos(album.photos),
});
export const cloneSmpDataPhotos = (photos: SmpDataPhoto[]): SmpDataPhoto[] => photos.map(cloneSmpDataPhoto);
export const cloneSmpDataPhoto = (photo: SmpDataPhoto): SmpDataPhoto => ({ ...photo });
