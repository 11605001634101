import React, { useContext } from 'react';
import useStyles from './styles';

import photo3 from './images/about-3.jpg';
import photo4 from './images/about-4.jpg';
import photo5 from './images/about-5.jpg';
import { useTitle } from '../../components/useTitle/useTitle';
import DataContext from '../../data/DataContext';
import { SmpDataAlbum, SmpDataPhoto } from '../../types/SmpData';
import Anchor from '../../components/Anchor/Anchor';

const getPhotoObj = (albums: SmpDataAlbum[], albumID: string, photoID: string): SmpDataPhoto => {
  const album = albums.find(({ id }) => id === albumID);
  return album ? album.photos.find(({ id }) => id === photoID) : undefined;
};
const getPhotoThumb = (albums: SmpDataAlbum[], albumID: string, photoID: string): string => {
  const photo = getPhotoObj(albums, albumID, photoID);
  return photo?.src_thumb;
};

const AboutPage = () => {
  const classes = useStyles();
  useTitle('About');

  const { getUrlBase, data } = useContext(DataContext);

  const { albums, references } = data;

  const dob = '1992-07-13';

  const age = Math.floor((Date.now() - Number(new Date(dob))) / (365.25 * 24 * 60 * 60 * 1000));

  return (
    <div className={classes.root}>
      <div className={classes.pics}>
        <div>
          <img src={photo5} alt="Jackie Cannon" />
        </div>
        <div>
          <img src={photo4} alt="Jackie Cannon" />
        </div>
        <div>
          <img src={photo3} alt="Jackie Cannon" />
        </div>
      </div>
      <div className={classes.body}>
        <div className={classes.factfile}>
          <div>Name</div>
          <div>Jackie Cannon</div>

          <div>Gender</div>
          <div>Male</div>

          <div>Pronouns</div>
          <div>he/him</div>

          <div>Age</div>
          <div>{age}</div>

          <div>Star sign</div>
          <div>Cancer</div>

          <div>Spouse</div>
          <div>
            Amanda Cannon
            <br />
            <span className={classes.factSub}>(married Aug 2018)</span>
          </div>

          <div>Alma mater</div>
          <div>University of Kent</div>

          <div>Nationality</div>
          <div>British 🇬🇧</div>

          <div>Residence</div>
          <div>
            Finsbury Park
            <br />
            North London
          </div>

          <div>Party trick</div>
          <div>Obscure facts</div>

          <div>
            Started
            <br />
            photography
          </div>
          <div>2015</div>

          <div>Camera</div>
          <div>Nikon Z7</div>
        </div>
        <div className={classes.content}>
          <p>I'm Jackie Cannon. I'm a {age}-year-old Male photographer based in London, England. My pronouns are he/him.</p>
          <p>
            I started shooting in 2015, and have experimented with a number of photographic types, including landscape, street and events. I now mainly pursue portrait photography, and have my own
            studio near Finsbury Park.
          </p>
          <p>
            I love meeting new people, and greatly enjoy the process of creating. Outside of photography, I have a number of other interests, including Game development, robotics, and making cool
            stuff. I'm a huge Arsenal fan, and love cheering Lewis Hamilton in F1.
          </p>
          <p>I live in North London with my wife (Amanda), my cat (Luna), and far too many houseplants.</p>
        </div>

        {!!references.length && (
          <div className={classes.references}>
            <h3>What do models say?</h3>
            {references.map((reference, index) => (
              <div key={index} className={classes.reference}>
                <div className={classes.refImage}>
                  <Anchor href={reference.model_url} type={reference.model_name} page="references" className={classes.refImageAnchor}>
                    <img src={getUrlBase() + (reference.img_src || getPhotoThumb(albums, reference.img_album, reference.img_photo))} alt={reference.model_name} />
                  </Anchor>
                </div>
                <div className={classes.refBody}>
                  <div className={classes.refText}>{reference.text}</div>
                  <div className={classes.refModel}>
                    <Anchor href={reference.model_url} type={reference.model_name} page="references">
                      {reference.model_name}
                    </Anchor>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default AboutPage;
