import React from 'react';

import { useTitle } from '../../components/useTitle/useTitle';
import levelImages from './levelImages';

import useStyles from './styles';

const renderLevelImgs = (imgs: string[], altText: string) => {
  return (
    <>
      {imgs.map((src, index) => (
        <img key={index} src={src} alt={altText} />
      ))}
    </>
  );
};

const LevelsPage = () => {
  const classes = useStyles();
  useTitle('Levels');

  return (
    <div className={classes.root}>
      <div className={classes.intro}>
        <h2>Modelling Levels</h2>
        <p>
          Levels are an industry term that gets thrown around when arranging shoots. For the uninitiated, it can be off-putting, but the aim is to gauge what a person is comfortable with, in the
          quickest and least uncomfortable way possible.
        </p>
        <p>In simple terms, your (i.e. the model's) 'levels' are the level of undress they're comfortable with. It's usually spoken about in terms of a scale (e.g. "I shoot up to lingerie").</p>
        <h3>Rules</h3>
        <p>Levels should be agreed when arranging the details of the shoot, and no one should ever ask you to shoot levels higher than agreed.</p>
        <h3>Levels</h3>
      </div>
      <div className={classes.section}>
        <div className={classes.imgs}>{renderLevelImgs(levelImages.portrait, 'Portrait / Fashion')}</div>
        <div className={classes.sectionBody}>
          <h4>Portrait / Fashion</h4>
          <p>This one is simple; fully clothed. Maybe a bit of cleavage or leg showing, but nothing you wouldn't wear walking down Oxford Street.</p>
        </div>
      </div>
      {/* <div className={classes.section}>
        <div className={classes.imgs}>
          {renderLevelImgs(levelImages.swimwear, "Swimwear")}
        </div>
        <div className={classes.sectionBody}>
          <h4>Swimwear</h4>
          <p>Bikinis, swimsuits, etc. What you might wear to the beach or swimming pool on holiday, but would put something on over to head into town.</p>
        </div>
      </div> */}
      <div className={classes.section}>
        <div className={classes.imgs}>{renderLevelImgs(levelImages.lingerie, 'Lingerie')}</div>
        <div className={classes.sectionBody}>
          <h4>Lingerie</h4>
          <p>Basically underwear. Bra and Knickers, bralettes, bodysuits, nighties, corsets, garters.</p>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.imgs}>{renderLevelImgs(levelImages.openShirt, 'Open Shirt')}</div>
        <div className={classes.sectionBody}>
          <h4>Open Shirt</h4>
          <p>Wearing a buttoned shirt or jacket with it undone. With no top or bra underneath, this shows a little bit of cleavage, but nipples aren't visible.</p>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.imgs}>{renderLevelImgs(levelImages.sheer, 'Sheer')}</div>
        <div className={classes.sectionBody}>
          <h4>Sheer</h4>
          <p>Sheer as in see-through. This is usually a mesh top/bra, but could also be where there's little bumps where the nipples are.</p>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.imgs}>{renderLevelImgs(levelImages.implied, 'Implied / Concealed')}</div>
        <div className={classes.sectionBody}>
          <h4>Implied / Concealed</h4>
          <p>
            This is where nudity is implied, but not shown. <b>Implied</b> is when you're actually wearing clothes, but it looks like you're not. <b>Concealed</b> is where you may actually be nude or
            topless, but everything is covered, either by hands/legs, the environment (e.g. a door, table, etc) or a prop (e.g. a bedsheet). Think Calendar Girls.
          </p>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.imgs}>{renderLevelImgs(levelImages.artNude, 'Topless / Art Nude')}</div>
        <div className={classes.sectionBody}>
          <h4>Topless / Art Nude</h4>
          <p>
            <b>Topless</b> is wearing nothing above the waist, but trousers/knickers/bottoms can be worn. For female models, this obviously means the breasts are seen. <b>Art Nude</b> is without any
            clothes, but the photos are artistic, not sleazy. Poses in art nude can be demure and flirty, but not raunchy or sexual. Knees stay together so that the vulva is not exposed too much.
          </p>
        </div>
      </div>
      <div className={classes.section}>
        <div className={classes.imgs}>{renderLevelImgs(levelImages.bodyPaint, 'Body Paint')}</div>
        <div className={classes.sectionBody}>
          <h4>Body Paint</h4>
          <p>Nude while having the body painted. This doesn't cover or obscure nudity, but shows it in an artistic context.</p>
        </div>
      </div>
      <div className={classes.finally}>
        <div className={classes.sectionBody}>
          <h4>More</h4>
          <p>There are more levels above this, but I don't shoot them, so they aren't worth mentioning here</p>
        </div>
      </div>
    </div>
  );
};

export default LevelsPage;
