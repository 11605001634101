import React, { useContext, useEffect, useState } from 'react';
import DataContext from '../../data/DataContext';
import { useImageLoaded } from '../../hooks/useImageLoaded';

import { FloatingPhotoProps, FloatingPhotoConfig } from './interfaces';
import useStyles from './styles';

import grubbyImg1 from './images/grubby-1.jpg';
import grubbyImg2 from './images/grubby-2.jpg';
import grubbyImg3 from './images/grubby-3.jpg';
import grubbyImg4 from './images/grubby-4.jpg';
import grubbyImg5 from './images/grubby-5.jpg';
import overlayImg from './images/overlay-1.png';

const grubbySrcs = [grubbyImg1, grubbyImg2, grubbyImg3, grubbyImg4, grubbyImg5];

export const FloatingPhoto = (props: FloatingPhotoProps) => {
  const { index, photo } = props;
  const [isInitial, setInitial] = useState<boolean>(true);
  const [imgRef, isLoaded, onImgLoad] = useImageLoaded();
  const classes = useStyles({
    ...props,
    isLoaded: isLoaded && !isInitial,
  } as FloatingPhotoConfig);
  const { getUrlBase } = useContext(DataContext);

  useEffect(() => {
    setInitial(false);
  }, []);

  return (
    <div className={classes.root}>
      <img alt="" src={getUrlBase() + photo.src_full} ref={imgRef} onLoad={onImgLoad} className={classes.photo} />
      <img alt="" src={grubbySrcs[index % grubbySrcs.length]} className={classes.grubby} />
      <img alt="" src={overlayImg} className={classes.overlay} />
    </div>
  );
};
