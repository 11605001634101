import React, { memo } from 'react';
import { makeStyles } from '../../utils/makeStyles';
import { LockOutlined as LockIcon } from '@material-ui/icons';
import { clx } from '../../utils/styleUtils';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    display: 'flex',
    flexFlow: 'column nowrap',
    placeItems: 'center',
    transform: 'translate(-50%, -50%)',
    padding: '1em 1em 1.5em 1em',
    borderRadius: '50%',
    opacity: 0.75,
    userSelect: 'none',
    pointerEvents: 'none',
    touchAction: 'none',

    '&:before': {
      content: '""',
      position: 'absolute',
      inset: '50%',
      boxShadow: (props: any) => (props.size === 'sm' ? '0 0 2em 3em rgba(var(--background-rgb), 0.5)' : '0 0 5em 7em rgba(var(--background-rgb), 0.5)'),
      zIndex: -1,
    },
  },
  icon: {
    '& > svg': {
      fontSize: (props: any) => (props.size === 'sm' ? '2rem' : '3rem'),
    },
  },
  text: {
    fontSize: (props: any) => (props.size === 'sm' ? '1.3rem' : '2rem'),
  },
}));

interface Props {
  className?: string;
  size?: 'sm' | 'lg';
}

const Locked = ({ className, size }: Props) => {
  const classes = useStyles({ size });

  return (
    <div className={clx(classes.root, className)}>
      <div className={classes.icon}>
        <LockIcon />
      </div>
      <div className={classes.text}>Locked</div>
    </div>
  );
};

export default memo(Locked);
