import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useTitle } from '../../components/useTitle/useTitle';
import { wait } from '../../utils/wait';
import DataContext from '../../data/DataContext';

import useStyles from './styles';

const LoginRedirectPage = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data } = useContext(DataContext);
  useTitle('Loading');

  useEffect(() => {
    // const code = getUrlParam('code');

    (async () => {
      if (data?.albums?.length > 0) {
        await wait(1000);
        history.push('/'); // remove all search params
      }
    })();
  }, [data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root}>
      <br />
      <h2 className={classes.title}>Loading...</h2>

      <br />
      <p>Hang tight</p>
      <br />
      <br />
      <br />
    </div>
  );
};

export default LoginRedirectPage;
