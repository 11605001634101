import { makeStyles } from '../../utils/makeStyles';

const small = '@media only screen and (max-width: 649px)';

const useStyles = makeStyles(() => ({
  root: {},
  pics: {
    display: 'flex',
    maxWidth: 600,
    margin: '0 auto',

    '& > div': {
      flex: '1 1 0px',
      aspectRatio: '1 / 1',

      '& > img': {
        maxWidth: '100%',
      },
    },
  },
  body: {
    width: '100%',
    // display: 'flex',
    // flexFlow: 'row nowrap',
    margin: '0 auto',
    maxWidth: '700px',
  },
  content: {
    // textAlign: 'justify',
    padding: '0 0.5em',
    margin: '1em auto',
    fontSize: '1.1rem',

    '& > p': {
      marginBottom: '0.5em',
    },

    [small]: {
      display: 'none',
    },
  },

  factfile: {
    float: 'right',
    display: 'grid',
    width: 'max-content',
    minWidth: 'max-content',
    height: 'min-content',
    gridTemplateColumns: 'auto auto',
    gap: '0.5em 1em',
    alignItems: 'center',
    padding: '0.75em 1em',
    margin: '0 0 0.5em 0.5em',
    backgroundColor: 'rgba(var(--primary-rgb), 0.1)',

    '& div:nth-child(odd)': {
      fontWeight: 'bold',
    },

    [small]: {
      // display: 'none',
      float: 'none',
      width: 'auto',
      margin: '0.5em',
    },
  },
  factSub: {
    fontSize: '0.8em',
    fontStyle: 'italic',
  },

  references: {
    display: 'flex',
    flexFlow: 'column nowrap',
    padding: '0 1em',
    width: 'fit-content',
  },
  reference: {
    display: 'flex',
    flexFlow: 'row nowrap',
    placeItems: 'center',
    margin: '0.5em 0 1em',

    '&:nth-child(even)': {
      flexDirection: 'row-reverse',

      '& $refBody': {
        // backgroundColor: 'red',
        textAlign: 'right',
      },
    },
  },
  refImage: {
    '& img': {
      width: '6em',
      borderRadius: '50%',
    },
  },
  refImageAnchor: {
    position: 'relative',
    display: 'inline-block',

    '&:after': {
      content: "''",
      position: 'absolute',
      inset: '-0.3em',
      border: '0.1em solid rgba(128, 128, 128, 0.5)',
      borderRadius: '50%',
    },
  },
  refBody: {
    display: 'flex',
    flexFlow: 'column nowrap',
    margin: '0 1em',
    maxWidth: 'calc(100% - 6em - 2em)',
  },
  refText: {
    marginBottom: '0.5em',
  },
  refModel: {
    fontWeight: 'bold',
  },
}));

export default useStyles;
