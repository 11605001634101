import React from 'react';
import ReactDOM from 'react-dom';
import { DataProvider } from './data/DataContext';
import './index.css';
import RootPage from './pages/RootPage/RootPage';

ReactDOM.render(
  <DataProvider>
    <RootPage />
  </DataProvider>,
  document.getElementById('root')
);
