import React from 'react';
import LazyLoad from 'react-lazy-load';

import { getIndexedPhotoRoute } from '../../utils/routes';
import { Link } from '../Link/Link';
import Locked from '../Locked/Locked';

import useStyles from './StandardTile.styles';
import TileProps from './TileProps';

const StandardTile = ({ item, album, imgProp, pathBase, isLocked, route }: TileProps) => {
  const classes = useStyles();

  return (
    <Link to={route || getIndexedPhotoRoute(album, item)} className={classes.root}>
      <LazyLoad debounce={false} offsetVertical={500} className={classes.lazyload}>
        <img alt="" src={pathBase + item[imgProp]} />
      </LazyLoad>
      {isLocked && <Locked size="sm" className={classes.locked} />}
    </Link>
  );
};

export default StandardTile;
