import React, { memo, useContext } from 'react';
import { makeStyles } from '../../utils/makeStyles';
import { LockOpenOutlined as LockOpenIcon } from '@material-ui/icons';
import DataContext from '../../data/DataContext';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'row nowrap',
    placeItems: 'center',
    placeContent: 'center',
    backgroundColor: 'rgba(var(--primary-rgb), 0.1)',
    margin: '0 auto',
    padding: '1em',
    userSelect: 'none',

    '& > span': {
      margin: '0 0.3em',
    },
    '& > svg': {
      fontSize: '1.5em',
    },
  },
}));

interface Props {}

const TokenLabel = (_props: Props) => {
  const classes = useStyles();

  const {
    data: { token_info },
  } = useContext(DataContext);

  return (
    <>
      {token_info && token_info.showLabel && (
        <div className={classes.root}>
          <LockOpenIcon />
          <span>{token_info.label}</span>
        </div>
      )}
    </>
  );
};

export default memo(TokenLabel);
