export const getUrlParam = (name: string = 'key', defaultValue?: string): string => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name) || defaultValue;
};

export const getKeyParam = (): string => getUrlParam('key');

const KEEP_PARAMS = ['key', 'api'];
export const filterSearch = (search: string): string => {
  const all = new URLSearchParams(search);

  const filtered = Object.fromEntries(KEEP_PARAMS.map(param => [param, all.get(param)]).filter(([param, value]) => value));

  return '?' + new URLSearchParams(filtered).toString();
};
