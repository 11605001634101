import { makeStyles } from '../../utils/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',

    '& h1': {
      fontSize: '4rem',
      marginTop: '0.1em',
    },
    '& h2': {
      fontSize: '2.25rem',
      marginBottom: '0.25em',
    },
    '& p': {
      fontSize: '1.5rem',
      marginBottom: '0.15em',
    },
    '& span': {
      color: 'var(--patreon)',
    },
  },
  title: {
    marginBottom: '1rem',
  },
  banner: {
    display: 'flex',
    width: '100%',
    flex: '0 0 0px',
    position: 'relative',
    aspectRatio: '1600 / 1000',
    alignItems: 'center',
    justifyContent: 'center',
    userSelect: 'none',
    // zIndex: -1,
  },
  bannerImg: {
    minWidth: '390px',
    pointerEvents: 'none',
  },
  bannerContent: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    gap: '0.5em',

    position: 'absolute',
    bottom: '4em',
    left: '50%',
    // width: '80%',

    fontSize: 'max(min(1.3em, 2.2vw), 14px)',
    transform: 'translateX(-50%)',

    '& > *': {
      position: 'relative',
      margin: 0,

      '&:before': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        height: '0px',
        width: 'calc(100% - 3em)',
        transform: 'translateX(-50%)',
        boxShadow: '0 0 10em 4em var(--background)',
        zIndex: '-1',
        pointerEvents: 'none',
        // opacity: '0.75',
        // outline: '1px solid blue',
      },
    },
  },

  lockedPhotos: {
    margin: '1em auto',
    width: '100%',
    maxWidth: 'calc(3 * 18em)',
  },
  lockedPhotosGrid: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    marginTop: '0.2em',

    '& > a': {
      width: `${100 / 3}%`,
      minWidth: '8em',
    },
  },

  asteriskAnchor: {
    color: 'rgba(var(--primary-rgb), 0.75)',
  },
  asterisk: {
    textAlign: 'right',
    fontSize: '11px',
  },

  charity: {
    'p&': {
      marginTop: '0.75em',
      fontSize: '1em',
    },

    '& span': {
      fontWeight: 'bold',
      color: '#0074D9',
      // background: '-webkit-linear-gradient(#0074D9 55%, #FFDC00 55%)',
      // '-webkit-background-clip': 'text',
      // textFillColor: 'transparent',

      '&:nth-child(even)': {
        color: '#FFDC00',
      },
    },
    '& i': {
      display: 'inline-block',
      height: '0.75em',
      aspectRatio: '3 / 2',
      background: 'linear-gradient(#0074D9 50%, #FFDC00 50%)',
      borderRadius: '0.1em',
    },
  },
}));

export default useStyles;
