import { makeStyles } from '../../utils/makeStyles';

const rem = (num = 1) => `${num}rem`;

const size = 5;
const spacing = 0.5;

const height = size + spacing + spacing;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexFlow: 'column nowrap',
    placeItems: 'stretch',
    fontSize: rem(1.5),
  },
  bio: {
    display: 'flex',
    flexFlow: 'column nowrap',
    placeItems: 'center',
    marginTop: rem(1),
    marginBottom: rem(1),

    '& > img': {
      width: rem(7.5),
      borderRadius: '50%',
      marginBottom: rem(0.2),
    },
  },
  list: {
    margin: '0 0.75rem',
    display: 'flex',
    flexFlow: 'column nowrap',
  },
  linkitem: {
    display: 'flex',
    flexFlow: 'row nowrap',
    marginBottom: rem(0.5),
    marginLeft: rem(-0.5),
    backgroundColor: 'rgba(var(--primary-rgb), 0.075)',
    height: rem(height),
    placeContent: 'stretch',
    placeItems: 'center',
    borderRadius: `${rem(height / 2)} 0 0 ${rem(height / 2)}`,
    color: 'var(--primary)',

    '&:hover': {
      backgroundColor: 'rgba(var(--primary-rgb), 0.125)',
      color: 'var(--primary)',
    },

    '& > img': {
      width: rem(size),
      height: rem(size),
      marginLeft: rem(spacing),
      marginRight: rem(1),
    },
  },
  loading: {
    textAlign: 'center',
    opacity: 0.25,
    marginTop: '1em',
  },
  loadingIcon: {
    fontSize: '1.5em',
    animation: '$spin 2s linear infinite, $fade-in 2s ease-in 1',
    animationFillMode: 'both',
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(-360deg)',
    },
  },
  '@keyframes fade-in': {
    '0%, 10%': {
      opacity: 0,
    },
    '100%': {
      opacity: 0.25,
    },
  },
}));

export default useStyles;
