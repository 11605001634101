import { makeStyles } from '../../utils/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: 800,
    margin: '0 auto',
    '& p, & h1, & h2, & h3, & h4, & h5': {
      marginBottom: '0.5em',
    },
  },
  intro: {
    // marginBottom: '1em',
  },
  section: {
    display: 'flex',
    flexFlow: 'row nowrap',
    placeItems: 'center',

    '&:nth-child(odd)': {
      textAlign: 'right',
      '& $imgs': {
        order: 1,
      },
    },
  },
  imgs: {
    flexShrink: 0,
    '& img': {
      width: '6em',
      maxWidth: '10vw',
    },
  },
  sectionBody: {
    margin: '0.5em 1em',
  },
  finally: {
    textAlign: 'center',
  },
}));

export default useStyles;
