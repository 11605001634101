import React, { memo, useContext, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import CloseIcon from '@material-ui/icons/Close';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { SmpDataAlbum } from '../../types/SmpData';
import { getAlbumRoute } from '../../utils/routes';

import useStyles from './styles';
import DataContext from '../../data/DataContext';
import { clx } from '../../utils/styleUtils';
import PatreonLink from '../PatreonLink/PatreonLink';
import Locked from '../Locked/Locked';
import { Link } from '../Link/Link';
import Exclusive from './Exclusive';
import { IndexedDataPhoto } from '../../types/Modified';
interface Props {
  album: SmpDataAlbum;
  photos: IndexedDataPhoto[];
  photo: IndexedDataPhoto;
}

const PhotoViewer = ({ album, photos, photo: initialPhoto }: Props) => {
  const classes = useStyles();
  const [photo, setPhoto] = useState<IndexedDataPhoto>(initialPhoto || photos[0]);

  const { getUrlBase } = useContext(DataContext);

  const currIndex = photos.indexOf(photo);
  const nextIndex = (currIndex + 1) % photos.length;
  const prevIndex = (currIndex + photos.length - 1) % photos.length;

  let newPhotoIndex = null;
  const onSwipeChangeIndex = swipeIndex => {
    newPhotoIndex = swipeIndex;
  };
  const onSwipeTransitionEnd = () => {
    if (newPhotoIndex !== null) {
      const newPhoto = photos[newPhotoIndex];
      setPhoto(newPhoto);
      newPhotoIndex = null;
    }
  };

  const change = (newIndex: number) => () => {
    setPhoto(photos[newIndex]);
  };

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <SwipeableViews enableMouseEvents index={currIndex} onChangeIndex={onSwipeChangeIndex} onTransitionEnd={onSwipeTransitionEnd}>
          {photos.map(mapPhoto => (
            <div key={mapPhoto.id} style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
              <img src={getUrlBase() + mapPhoto.src_full} alt={album.title} className={clx(classes.img, mapPhoto.blurred && classes.imgLocked)} />
              {mapPhoto.blurred && <Locked />}
              {mapPhoto.blurred && !mapPhoto.exclusive && <PatreonLink album={album} page={album.name + '/' + mapPhoto.albumIndex} type="photo" className={classes.patreon} />}
              {!mapPhoto.blurred && mapPhoto.exclusive && <Exclusive />}
            </div>
          ))}
        </SwipeableViews>
      </div>
      <div className={classes.buttons}>
        <div onClick={change(prevIndex)} className={clx(classes.panel, classes.panelLeft)}>
          <div className={classes.iconContainer}>
            <ChevronLeftIcon />
          </div>
        </div>
        <div onClick={change(nextIndex)} className={clx(classes.panel, classes.panelRight)}>
          <div className={classes.iconContainer}>
            <ChevronRightIcon />
          </div>
        </div>
      </div>
      <div className={classes.counter}>
        <span>{currIndex + 1}</span>
        <span>/</span>
        <span>{photos.length}</span>
      </div>
      <Link to={getAlbumRoute(album)} className={classes.close}>
        <CloseIcon />
      </Link>
    </div>
  );
};

export default memo(PhotoViewer);
