export const fetchPath = async (url: string = '', params: any, options: any = optionsGET()) => {
  const paramStr = new URLSearchParams(params).toString();

  const result = await fetch(url + (paramStr ? paramStr : ''), options);
  if (result.status === 401) {
    throw new Error("Sorry fella - You don't have permission to do that");
  } else if (result.status === 403) {
    throw new Error("Sorry fella - You don't have permission to do that");
  } else {
    return result;
  }
};

const buildOptions = (method: string = 'GET', body?: any, headers: any = {}, extend: any = {}) => ({
  method,
  headers: {
    ...headers,
  },
  body,
  ...extend,
});

// different options
export const optionsGET = (body?: any, headers: any = {}, extend: any = {}) => buildOptions('GET', body, headers, extend);

export const optionsPOST = (body?: any, headers: any = {}, extend: any = {}) => buildOptions('POST', body, headers, extend);

export const headersJSON = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};
