import { makeStyles } from '../../utils/makeStyles';

const useStyles = makeStyles(() => ({
  root: {},
  infoBox: {
    padding: '0.2rem 0.5rem',
    textAlign: 'center',
  },
  patreon: {
    display: 'block',
    backgroundColor: 'var(--patreon)',
    margin: '0.5em',
    '& a': {
      color: 'white',
      padding: '1em',
    },
    '& a:hover': {
      color: '#f0f0f0',
    },
  },
  anchor: {
    display: 'flex',
    flexFlow: 'row nowrap',
    placeItems: 'center',
    placeContent: 'center',

    '& > div:first-child': {
      display: 'flex',
      marginRight: '0.5em',
    },
  },
}));

export default useStyles;
