import { SmpData } from '../types/SmpData';
import { getUrlParam } from '../utils/params';
import { fetchPath } from './fetch';

const URL_BASE = getUrlParam('api', 'https://kzfaqvafyk5wkldzu2lzf3uzu40pgqnr.lambda-url.eu-west-2.on.aws');
const DATA_URL = URL_BASE + '?';
const STORAGE_KEY = 'patreon:token';

export const getSavedToken = () => localStorage.getItem(STORAGE_KEY);
export const setSavedToken = (value: string) => {
  if (value) {
    localStorage.setItem(STORAGE_KEY, value);
  }
};
export const removeSavedToken = () => localStorage.removeItem(STORAGE_KEY);

export const safeFetch = async <T extends unknown>(get: () => T, maxAttempts: number = 10, errorCount: number = 0): Promise<T> => {
  try {
    const result: T = await get();
    return result;
  } catch (err) {
    console.warn('Response error:', err);
    if (errorCount < maxAttempts) {
      return safeFetch(get, maxAttempts, errorCount + 1);
    }
    throw new Error(`Max attempts (${maxAttempts}) made without success`);
  }
};

export const getPatreonData = async (code?: string, token?: string): Promise<SmpData> => {
  const data = await safeFetch(async () => {
    const data: { code?: string; token?: string } = {};

    if (code) data.code = code;
    if (token) data.token = token;

    const response = await fetchPath(DATA_URL, data);
    return await response.json();
  });

  setSavedToken(data.token);
  return data;
};
