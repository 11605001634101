import { makeStyles } from '../../utils/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    margin: '0',
    cursor: 'pointer',
    width: 'var(--tile-size)',
    height: 'var(--tile-size)',

    '& img': {
      width: '100%',
    },
  },

  locked: {
    opacity: 0.5,
  },

  lazyload: {
    display: 'flex',
  },
}));

export default useStyles;
