import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Header from '../../components/Header/Header';

import HomePage from '../HomePage/HomePage';
import AlbumPage from '../AlbumPage/AlbumPage';

import HistoryLogger from './HistoryLogger';
import ScrollToTop from './ScrollToTop';
import useStyles from './styles';
import AboutPage from '../AboutPage/AboutPage';
import ContactPage from '../ContactPage/ContactPage';
import ModeSetter from './ModeSetter';
import LinksPage from '../LinksPage/LinksPage';
import LevelsPage from '../LevelsPage/LevelsPage';
import FailedLabel from './FailedLabel';
import LoginPage from '../LoginPage/LoginPage';
import LoginRedirectPage from '../LoginPage/LoginRedirectPage';
import AccountPage from '../AccountPage/AccountPage';

const RootPage = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Router>
        <HistoryLogger />
        <ModeSetter />
        <main className={classes.content}>
          <Header />

          <div className={classes.main}>
            <FailedLabel />
            <Switch>
              <Route path="/view/:albumName/:photoIndex">
                <AlbumPage />
              </Route>
              <Route path="/view/:albumName">
                <AlbumPage />
              </Route>
              <Route path="/login/redirect">
                <ScrollToTop />
                <LoginRedirectPage />
              </Route>
              <Route path="/login">
                <ScrollToTop />
                <LoginPage />
              </Route>
              <Route path="/account">
                <ScrollToTop />
                <AccountPage />
              </Route>
              <Route path="/levels">
                <ScrollToTop />
                <LevelsPage />
              </Route>
              <Route path="/links">
                <ScrollToTop />
                <LinksPage />
              </Route>
              <Route path="/about">
                <ScrollToTop />
                <AboutPage />
              </Route>
              <Route path="/contact">
                <ScrollToTop />
                <ContactPage />
              </Route>
              <Route path="/">
                <ScrollToTop />
                <HomePage />
              </Route>
            </Switch>
          </div>
        </main>
      </Router>
    </div>
  );
};

export default RootPage;
