import ReactGA from 'react-ga';
import { SmpData } from '../types/SmpData';
import { getKeyParam, getUrlParam } from '../utils/params';
import { fetchPath, optionsGET as GET } from './fetch';
import { getPatreonData, getSavedToken } from './patreon';

const source = 'https://cannon-cms.s3.eu-west-2.amazonaws.com/';

const getDataKey = (): string => {
  const key = getKeyParam();
  if (key) {
    return key;
  }
  if (window.location.host.startsWith('portfolio')) {
    return 'portfolio';
  }
  return 'main';
};

const getPath = (key = getDataKey()): string => {
  const fileName = key ? `data-${key}.json` : `data.json`;
  return source + fileName;
};

const getStaticData = async (key: string) => {
  const response = await fetchPath(
    getPath(key),
    undefined,
    GET(undefined, {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    }),
  );
  const json = (await (response as any).json()) as SmpData;

  return json;
};

export const getDataFromServer = async (): Promise<SmpData> => {
  const key = getKeyParam();
  const code = getUrlParam('code');
  const savedToken = getSavedToken();

  if (!key && (code || savedToken)) {
    try {
      const patData = code ? getPatreonData(code, undefined) : getPatreonData(undefined, savedToken);
      return patData;
    } catch (err) {
      console.warn(err);
    }
  }

  const json = await getStaticData(key);

  if (key && json && json.token_info) {
    console.log('GA event sent');
    ReactGA.event(
      {
        category: 'dataKeyUsed',
        action: json.token_info.tag,
        label: json.token_info.account?.email || json.token_info.label,
      },
      [json.token_info.tag],
    );
  }

  return json;
};
