import React from 'react';
import InstagramIcon from '@material-ui/icons/Instagram';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { clx } from '../../utils/styleUtils';

import useStyles from './styles';
import Anchor from '../../components/Anchor/Anchor';
import { useTitle } from '../../components/useTitle/useTitle';

const ContactPage = () => {
  const classes = useStyles();
  useTitle('Contact');

  return (
    <div className={classes.root}>
      <h2 className={classes.title}>Contact</h2>

      <p>Have an idea for a shoot? Want to collaborate?</p>
      <p>Here are some ways to get in touch:</p>

      <div className={classes.list}>
        <Anchor href="https://www.instagram.com/_jackiecannon/" className={clx(classes.li, classes.insta)} type="instagram" page="contact">
          <InstagramIcon />
          &nbsp;_jackiecannon
        </Anchor>
        <Anchor href="mailto:jackiec@annon.co.uk" className={clx(classes.li, classes.email)} type="email" page="contact">
          <MailOutlineIcon />
          &nbsp;jackiec@annon.co.uk
        </Anchor>
      </div>
    </div>
  );
};

export default ContactPage;
