import React, { useContext } from 'react';
import { Loop as LoopIcon } from '@material-ui/icons';
import useStyles from './styles';

import * as logos from './logos/logos';

import { Link } from '../../components/Link/Link';
import Anchor from '../../components/Anchor/Anchor';
import DataContext from '../../data/DataContext';
import { SmpDataLink } from '../../types/SmpData';

import profPic from './profpic.png';
import { useTitle } from '../../components/useTitle/useTitle';

const AnchoredItem = (classes, { id, text, url, logo }: SmpDataLink) => {
  return (
    <Anchor className={classes.linkitem} href={url} page="links" type={text} key={id}>
      <img src={logos[logo] || logos.link} alt={text} />
      {text}
    </Anchor>
  );
};
const LinkedItem = (classes, { id, text, url, logo }: SmpDataLink) => {
  return (
    <Link className={classes.linkitem} to={url} key={id}>
      <img src={logos[logo] || logos.link} alt={text} />
      {text}
    </Link>
  );
};

// const defaultLinks = [
//   {
//     id: 'jackie',
//     enabled: true,
//     text: 'Online Portfolio',
//     url: '/',
//     logo: 'jackie',
//   },
//   {
//     id: 'insta',
//     enabled: true,
//     text: 'Instagram',
//     url: 'https://www.instagram.com/_jackiecannon/',
//     logo: 'instagram',
//   },
//   {
//     id: 'twitter',
//     enabled: true,
//     text: 'Twitter',
//     url: 'https://twitter.com/_jackiecannon',
//     logo: 'twitter',
//   },
//   {
//     id: 'facebook',
//     enabled: true,
//     text: 'Facebook',
//     url: 'https://www.facebook.com/jackcannonphotography',
//     logo: 'facebook',
//   },
//   {
//     id: 'patreon',
//     enabled: true,
//     text: 'Patreon',
//     url: 'https://www.patreon.com/jackiecannon',
//     logo: 'patreon',
//   },
//   {
//     id: 'google',
//     enabled: true,
//     text: 'Google',
//     url: 'https://www.google.com',
//     logo: 'link',
//   },
// ];

const LinksPage = () => {
  const classes = useStyles();
  useTitle('');
  const { data } = useContext(DataContext);

  const links: SmpDataLink[] = data.links && data.links.length ? data.links : [];
  // const links: DataLink[] = defaultLinks;

  return (
    <div className={classes.root}>
      <div className={classes.bio}>
        <img src={profPic} alt="Jackie Cannon" />
        <div>Jackie Cannon</div>
      </div>

      {!!data.links.length && <div className={classes.list}>{links.map(config => (config.url[0] === '/' ? LinkedItem(classes, config) : AnchoredItem(classes, config)))}</div>}
      {!data.links.length && (
        <div className={classes.loading}>
          <LoopIcon className={classes.loadingIcon} />
        </div>
      )}
    </div>
  );
};

export default LinksPage;
