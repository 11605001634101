import React, { memo, useContext } from 'react';
import { makeStyles } from '../../utils/makeStyles';
import { LockOpenOutlined as LockOpenIcon } from '@material-ui/icons';
import DataContext from '../../data/DataContext';

const noHover = '@media (hover: none)';

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    left: '50%',
    bottom: '2em',
    display: 'flex',
    flexFlow: 'row nowrap',
    placeItems: 'center',
    placeContent: 'center',
    transform: 'translate(-50%, 0)',
    backgroundColor: 'var(--background)',
    padding: '1em',
    opacity: 0.25,
    userSelect: 'none',
    transition: '0.5s opacity',

    '&:hover': {
      opacity: 1,
    },
    [noHover]: {
      fontSize: '0.5em',
      opacity: 0.5,
      padding: '0.5em',
    },

    '& > span': {
      margin: '0 0.25em',
    },
    '& > svg': {
      fontSize: '1.5em',
    },
  },
  bold: {
    fontWeight: 'bold',
  },
}));

interface Props {}

const Exclusive = (_props: Props) => {
  const classes = useStyles();

  const {
    data: { token_info },
  } = useContext(DataContext);

  return (
    <>
      {token_info && token_info.showExclusive && (
        <div className={classes.root}>
          <LockOpenIcon />
          <span className={classes.bold}>Exclusive!</span>
          <span>This photo is usually locked</span>
        </div>
      )}
    </>
  );
};

export default memo(Exclusive);
