import { makeStyles } from '../../utils/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'rgba(var(--background-rgb), 0.75)',
    userSelect: 'none',
  },
  main: {
    position: 'absolute',
    display: 'flex',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    maxWidth: '100vw',
    maxHeight: '100vh',
  },
  imgLocked: {},

  buttons: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    touchAction: 'none',
    pointerEvents: 'none',
  },
  panel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 'min(50vw, max(15vw, 15em))',
    touchAction: 'auto',
    pointerEvents: 'all',

    '&:hover $iconContainer': {
      backgroundColor: 'rgba(var(--primary-rgb), 0.075)',
    },
  },
  panelLeft: {
    justifyContent: 'flex-start',
  },
  panelRight: {
    justifyContent: 'flex-end',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '2rem',
    width: '1.1em',
    height: '1.1em',
    borderRadius: '50%',
    color: 'var(--primary)',
    margin: '0.1em',
    transition: 'background 0.2s',

    animation: '$disappear 0.4s ease-in',
    animationDelay: '5s',
    animationFillMode: 'both',

    '& > *': {
      fontSize: '1em',
    },
  },
  '@keyframes disappear': {
    '0%': { opacity: 1 },
    '100%': { opacity: 0 },
  },

  counter: {
    position: 'absolute',
    top: 0,
    left: 0,
    padding: '0.5em',
    fontSize: '1.2rem',
    backgroundColor: 'rgba(var(--primary-rgb), 0.075)',
    color: 'var(--primary)',
    minWidth: '4.5em',
    fontVariantNumeric: 'tabular-nums',

    display: 'flex',

    '& span': {
      padding: '0 0.25em',
    },

    '& span:first-child': {
      flex: '1 1 0px',
      paddingLeft: '0',
      textAlign: 'right',
    },
    '& span:nth-child(2)': {
      flex: '0 0 0.75em',
      textAlign: 'center',
      padding: '0 0.25em',
    },
    '& span:last-child': {
      flex: '1 1 0px',
      paddingRight: '0',
      textAlign: 'left',
    },
  },

  close: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '1.5em',
    height: '1.5em',
    fontSize: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    '&:hover': {
      backgroundColor: 'rgba(var(--primary-rgb), 0.075)',
      color: 'var(--primary)',
    },

    '& > *': {
      fontSize: '1em',
    },
  },

  patreon: {
    position: 'absolute',
    bottom: '1rem',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '80%',
    maxWidth: '500px',
    margin: '0 !important',
  },

  '@media (hover: none)': {
    panel: {
      display: 'none',
    },
    close: {
      '&, &:hover': {
        backgroundColor: 'rgba(var(--primary-rgb), 0.075)',
        color: 'var(--primary)',
      },
    },
  },
}));

export default useStyles;
