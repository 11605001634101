import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Drawer } from '@material-ui/core';

import InstagramIcon from '@material-ui/icons/Instagram';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import {
  HomeRounded as HomeIcon,
  Twitter as TwitterIcon,
  CameraRounded as AboutIcon,
  PersonRounded as AccountIcon,
  SendRounded as ContactIcon,
  LockOpenRounded as LogInIcon,
} from '@material-ui/icons';

import { clx } from '../../utils/styleUtils';
import useStyles from './styles';
import Anchor from '../Anchor/Anchor';
import { Link, NavLink } from '../Link/Link';

import logoPaper from './logo-paper.png';
import PatreonIcon from '../icons/PatreonIcon';
import DataContext from '../../data/DataContext';

const Header = () => {
  const { pathname } = useLocation();
  const [showMenu, setMenu] = useState<boolean>(false);
  const { data, isLoaded } = useContext(DataContext);
  const classes = useStyles();

  const openMenu = () => setMenu(true);
  const closeMenu = () => setMenu(false);
  const toggleMenu = () => setMenu(!showMenu);

  // wow, past me, what a hack!
  if (pathname === '/links') {
    return <></>;
  }

  const showUpselling = isLoaded && data && (!data.token_info || data.token_info.showUpselling);
  const account = isLoaded && data && data.token_info ? data.token_info.account : null;

  return (
    <>
      <div className={classes.root}>
        <div className={classes.content}>
          <div className={classes.smallLeft} onClick={openMenu}>
            <MenuIcon />
          </div>
          <div className={clx(classes.fullSide, classes.fullLeft)}>
            <Link to="/" className={classes.navItem}>
              <HomeIcon />
            </Link>
            {showUpselling && !account && (
              <NavLink exact to="/login" className={clx(classes.navItem, classes.navItemResponsive)} activeClassName={classes.active}>
                <LogInIcon />
                <span>Log&nbsp;In</span>
              </NavLink>
            )}
            {showUpselling && account && (
              <NavLink exact to="/account" className={classes.navItem} activeClassName={classes.active}>
                <AccountIcon />
              </NavLink>
            )}
            <NavLink exact to="/about" className={clx(classes.navItem, classes.navItemResponsive)} activeClassName={classes.active}>
              <AboutIcon />
              <span>About</span>
            </NavLink>
            <NavLink exact to="/contact" className={clx(classes.navItem, classes.navItemResponsive)} activeClassName={classes.active}>
              <ContactIcon />
              <span>Contact</span>
            </NavLink>
          </div>
          <div className={classes.centre}>
            <Link to="/">
              {/* <img className={clx(classes.logo, classes.dark)} src={logoDark} alt="Jackie Cannon Logo" /> */}
              {/* <img className={clx(classes.logo, classes.light)} src={logoLight} alt="Jackie Cannon Logo" /> */}
              <img className={clx(classes.logo)} src={logoPaper} alt="Jackie Cannon Logo" />
            </Link>
          </div>
          <div className={clx(classes.fullSide, classes.fullRight)}>
            <Anchor href="https://www.instagram.com/_jackiecannon/" className={clx(classes.navItem, classes.instagram)} type="instagram" page="navbar">
              <InstagramIcon />
            </Anchor>
            {showUpselling && (
              <Anchor href="https://www.patreon.com/jackiecannon" className={clx(classes.navItem, classes.patreon)} type="patreon" page="navbar">
                <PatreonIcon style={{ fontSize: '1.2em' }} />
              </Anchor>
            )}
            <Anchor href="https://twitter.com/_jackiecannon" className={clx(classes.navItem, classes.twitter)} type="twitter" page="navbar">
              <TwitterIcon />
            </Anchor>
          </div>
        </div>
      </div>
      <Drawer
        anchor={'left'}
        open={showMenu}
        onClose={toggleMenu}
        className={classes.drawerContainer}
        PaperProps={{
          className: classes.drawer,
        }}
      >
        <div className={classes.drawerClose}>
          <div onClick={closeMenu}>
            <CloseIcon />
          </div>
        </div>

        <NavLink exact to="/" className={clx(classes.drawerItem, classes.drawerItemFlex, classes.drawerItemLeft)} activeClassName={classes.activeDrawerItem} onClick={closeMenu}>
          <HomeIcon />
          <span>Home</span>
        </NavLink>
        {showUpselling && !account && (
          <NavLink exact to="/login" className={clx(classes.drawerItem, classes.drawerItemFlex, classes.drawerItemLeft)} activeClassName={classes.activeDrawerItem} onClick={closeMenu}>
            <LogInIcon />
            <span>Log In</span>
          </NavLink>
        )}
        {showUpselling && account && (
          <NavLink exact to="/account" className={clx(classes.drawerItem, classes.drawerItemFlex, classes.drawerItemLeft)} activeClassName={classes.activeDrawerItem} onClick={closeMenu}>
            <AccountIcon />
            <span>Account</span>
          </NavLink>
        )}
        <NavLink exact to="/about" className={clx(classes.drawerItem, classes.drawerItemFlex, classes.drawerItemLeft)} activeClassName={classes.activeDrawerItem} onClick={closeMenu}>
          <AboutIcon />
          <span>About</span>
        </NavLink>
        <NavLink exact to="/contact" className={clx(classes.drawerItem, classes.drawerItemFlex, classes.drawerItemLeft)} activeClassName={classes.activeDrawerItem} onClick={closeMenu}>
          <ContactIcon />
          <span>Contact</span>
        </NavLink>

        <br />

        <Anchor href="https://www.instagram.com/_jackiecannon/" className={clx(classes.drawerItem, classes.drawerItemFlex, classes.instagram)} onClick={closeMenu} type="instagram" page="drawer">
          <InstagramIcon />
          <span>_jackiecannon</span>
        </Anchor>
        {showUpselling && (
          <Anchor href="https://www.patreon.com/jackiecannon" className={clx(classes.drawerItem, classes.drawerItemFlex, classes.patreon)} onClick={closeMenu} type="patreon" page="drawer">
            <PatreonIcon style={{ fontSize: '1em', padding: '0 0.075em 0 0.125em' }} />
            <span>jackiecannon</span>
          </Anchor>
        )}
        <Anchor href="https://twitter.com/_jackiecannon" className={clx(classes.drawerItem, classes.drawerItemFlex, classes.twitter)} onClick={closeMenu} type="twitter" page="drawer">
          <TwitterIcon />
          <span>_jackiecannon</span>
        </Anchor>
      </Drawer>
    </>
  );
};

export default Header;
