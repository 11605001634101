import React, { useContext } from 'react';

import ReactGA from 'react-ga';
import DataContext from '../../data/DataContext';

export interface AnchorProps extends React.HTMLProps<HTMLAnchorElement> {
  type?: string; // e.g. instagram, patreon
  page?: string; // e.g. jess-johanson
}

const Anchor = (props: AnchorProps) => {
  const { type, page, children, onClick, ...other } = props;
  const { data } = useContext(DataContext);

  const tracker = data?.token_info?.tag || 'REGULAR';

  const overrideOnClick: React.MouseEventHandler<HTMLAnchorElement> = (...args) => {
    console.log('Sending GA event:', type, page);
    ReactGA.event(
      {
        category: 'anchorClick',
        action: type || '-- not set --',
        label: page || '-- not set --',
      },
      [tracker],
    );

    if (onClick) {
      onClick(...args);
    }
  };

  return (
    <a {...other} onClick={overrideOnClick}>
      {children}
    </a>
  );
};

export default Anchor;
