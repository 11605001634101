import { makeStyles } from '../../utils/makeStyles';

const full = '@media only screen and (min-width: 800px)';
const small = '@media only screen and (max-width: 799px)';

const showNavText = '@media only screen and (min-width: 1000px)';
const showNavIcons = '@media only screen and (max-width: 999px)';

const dark = '@media (prefers-color-scheme: dark)';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    marginBottom: '0.5em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    width: 'var(--maxwidth)',
    maxWidth: '100vw',
  },
  centre: {
    flexGrow: 0,
    flexShrink: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    [small]: {
      alignItems: 'flex-end',
    },
  },
  logo: {
    width: '15em',
    marginBottom: '-0.6em',
  },
  dark: {
    display: 'none',
    [dark]: {
      display: 'inherit',
    },
  },
  light: {
    [dark]: {
      display: 'none',
    },
  },

  smallLeft: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 1em',
    cursor: 'pointer',

    '& > *': {
      fontSize: '2.5em',
    },

    [full]: {
      display: 'none',
    },
  },

  fullSide: {
    display: 'flex',
    flex: '1 1 0px',
    [small]: {
      display: 'none',
    },
  },
  fullLeft: {
    justifyContent: 'flex-start',
  },
  fullRight: {
    justifyContent: 'flex-end',
  },

  navItem: {
    position: 'relative',
    color: 'var(--primary)',
    textDecoration: 'none',
    lineHeight: '1.4em',
    height: '100%',
    padding: '0 1em',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    transition: 'background-color 0.2s',

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 0,
      backgroundColor: 'rgba(var(--primary-rgb), 0.05)',
      transition: 'height 0.2s, background-color 0.2s',
    },

    '&:hover': {
      backgroundColor: 'rgba(var(--primary-rgb), 0.05)',
      color: 'var(--primary)',

      '&:after': {
        height: '0.5em',
      },
    },

    '& > *': {
      verticalAlign: 'bottom',
    },
  },
  navItemResponsive: {
    [showNavIcons]: {
      '& span': {
        display: 'none',
      },
    },
    [showNavText]: {
      '& svg': {
        display: 'none',
      },
    },
  },

  active: {
    backgroundColor: 'rgba(var(--active-rgb), 0.05)',
    '&:after': {
      backgroundColor: 'rgba(var(--active-rgb), 0.1)',
      height: '0.5em',
    },
    '&:hover': {
      backgroundColor: 'rgba(var(--active-rgb), 0.1)',
    },
  },

  drawerContainer: {
    [full]: {
      display: 'none',
    },
  },
  drawer: {
    backgroundColor: 'var(--background) !important',
    color: 'var(--primary) !important',
    minWidth: '200px',
    padding: '0',
  },
  drawerClose: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& > *': {
      width: '1.5em',
      height: '1.5em',
      fontSize: '1.5rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: 'rgba(var(--primary-rgb), 0.05)',
        color: 'var(--primary-rgb)',
      },

      '& > *': {
        fontSize: '1em',
      },
    },
  },
  drawerItem: {
    fontSize: '1.25em',
    padding: '0.25em 1em 0.25em 1em',
    position: 'relative',

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      top: 0,
      right: 0,
      width: 0,
      backgroundColor: 'rgba(var(--primary-rgb), 0.05)',
      transition: 'width 0.2s',
    },

    '&:hover': {
      backgroundColor: 'rgba(var(--primary-rgb), 0.05)',
      color: 'var(--primary)',

      '&:after': {
        width: '0.5em',
      },
    },
  },
  activeDrawerItem: {
    backgroundColor: 'rgba(var(--active-rgb), 0.05)',

    '&:after': {
      backgroundColor: 'rgba(var(--active-rgb), 0.1)',
      width: '0.5em',
    },
    '&:hover': {
      backgroundColor: 'rgba(var(--active-rgb), 0.1)',
    },
  },
  drawerItemFlex: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > *:not(:last-child)': {
      marginRight: '0.5em',
    },
  },
  drawerItemLeft: {
    justifyContent: 'flex-start',
  },

  instagram: {
    '&:hover': {
      backgroundColor: 'rgba(var(--instagram-rgb), 0.05)',
      color: 'var(--instagram)',
      '&:after': {
        backgroundColor: 'rgba(var(--instagram-rgb), 0.05)',
      },
    },
  },
  patreon: {
    '&:hover': {
      backgroundColor: 'rgba(var(--patreon-rgb), 0.05)',
      color: 'var(--patreon)',
      '&:after': {
        backgroundColor: 'rgba(var(--patreon-rgb), 0.05)',
      },
    },
  },
  twitter: {
    '&:hover': {
      backgroundColor: 'rgba(var(--twitter-rgb), 0.05)',
      color: 'var(--twitter)',
      '&:after': {
        backgroundColor: 'rgba(var(--twitter-rgb), 0.05)',
      },
    },
  },
}));

export default useStyles;
