import { makeStyles } from '../../utils/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center',
  },
  title: {
    marginBottom: '1rem',
  },

  table: {
    display: 'flex',
    flexFlow: 'column nowrap',
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    fontSize: '1.2rem',

    '& > *': {
      display: 'flex',
      flexFlow: 'row nowrap',
      marginBottom: '1rem',

      '& > *:nth-child(odd)': {
        flex: '0 0 100px',
      },
      '& > *:nth-child(even)': {
        flex: '0 0 300px',
      },
    },
  },

  button: {
    '&, &:active, &:focus, &:visited': {
      display: 'inline-flex',
      padding: '0.5rem 1rem',
      backgroundColor: 'var(--primary)',
      color: 'var(--background)',
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
