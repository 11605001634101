import React, { useContext } from 'react';

import useStyles from './styles';
import { useTitle } from '../../components/useTitle/useTitle';

import bannerImg from './images/loginbanner.png';
import { FloatingPhoto } from '../../components/FloatingPhoto/FloatingPhoto';
import DataContext from '../../data/DataContext';
import { PatreonLoginBtn } from '../../components/PatreonLoginBtn/PatreonLoginBtn';
import StandardTile from '../../components/Grid/StandardTile';
import { getAlbumRoute } from '../../utils/routes';
import { SmpData, SmpDataAlbum, SmpDataPhoto } from '../../types/SmpData';

const randomiseArray = (array: any[]) => {
  const randoms = array.map(() => Math.random());
  return array.sort((a, b) => randoms[a] - randoms[b]);
};

const getPair = (isLocked: boolean) => (album: SmpDataAlbum) => {
  const filteredPhotos = album.photos.filter(photo => (photo.blurred || photo.exclusive) === isLocked);
  return { album, photo: filteredPhotos[Math.floor(Math.random() * filteredPhotos.length)] };
};
const getOnePhotoPerAlbum = (data: SmpData, isLocked: boolean): { photo: SmpDataPhoto; album: SmpDataAlbum }[] =>
  randomiseArray(
    data.albums
      .slice(Math.round(data.albums.length / 2) * -1)
      .map(getPair(isLocked))
      .filter(pair => pair && pair.photo && pair.album),
  );

const LoginPage = () => {
  const classes = useStyles();
  const { data, getUrlBase } = useContext(DataContext);
  useTitle('Login');

  const oneUnlockedPhotoPerAlbum = getOnePhotoPerAlbum(data, false);
  const oneLockedPhotoPerAlbum = getOnePhotoPerAlbum(data, true);

  const headerPhotos = oneUnlockedPhotoPerAlbum.slice(0, 5).map(({ photo }) => photo);
  const tilePhotos = oneLockedPhotoPerAlbum.slice(0, 9);

  const lockedCount = data?.info ? data.info.exclusivePhotos - 9 : null;

  return (
    <div className={classes.root}>
      <div className={classes.banner}>
        <img src={bannerImg} className={classes.bannerImg} alt="Banner with lots of images" />
        {headerPhotos[0] && <FloatingPhoto index={0} pos={[25, 30]} starting={[-100, -100]} rotation={-3} photo={headerPhotos[0]} />}
        {headerPhotos[1] && <FloatingPhoto index={1} pos={[70, 25]} starting={[100, -100]} rotation={-12} photo={headerPhotos[1]} />}
        {headerPhotos[2] && <FloatingPhoto index={2} pos={[15, 70]} starting={[-100, 0]} rotation={3} photo={headerPhotos[2]} />}
        {headerPhotos[3] && <FloatingPhoto index={3} pos={[48, 50]} starting={[0, -100]} rotation={-4} photo={headerPhotos[3]} />}
        {headerPhotos[4] && <FloatingPhoto index={4} pos={[80, 70]} starting={[100, 0]} rotation={12} photo={headerPhotos[4]} />}
        <div className={classes.bannerContent}>
          <PatreonLoginBtn display="Sign Up with Patreon" url="https://www.patreon.com/jackiecannon" />
          <PatreonLoginBtn display="Login" color="#808080" text="white" />
        </div>
      </div>

      <h1>
        <span>Unlock</span> it all!
      </h1>
      <h2>
        Help <span>support me</span> create wonderful photos, and get <span>access to exclusive content</span>
        <a href="#asterisk-patrons" className={classes.asteriskAnchor}>
          <sup>*</sup>
        </a>
      </h2>

      <p>
        Sign in using Patreon to see all these locked photos,{' '}
        <span>
          and <b>{lockedCount ? `${lockedCount}+` : 'many'}</b> more!
        </span>
      </p>
      <p>
        <span>New content</span> added every week!
      </p>
      <p className={classes.charity}>
        <i /> A portion of proceeds are <span>donated</span> to charities aiding humanitarian efforts in <span>Ukraine</span>!
        <a href="#asterisk-charity" className={classes.asteriskAnchor}>
          <sup>†</sup>
        </a>{' '}
        <i />
      </p>

      {!!tilePhotos.length && (
        <div className={classes.lockedPhotos}>
          <div className={classes.lockedPhotosGrid}>
            {tilePhotos.map(({ album, photo }, index) => (
              <StandardTile key={index + '-' + photo.id} item={photo} imgProp="src_thumb" pathBase={getUrlBase()} isLocked={photo.blurred} route={getAlbumRoute(album)}></StandardTile>
            ))}
          </div>
        </div>
      )}

      <div className={classes.asterisk} id="asterisk-patrons">
        <b>*</b> Only Patrons who are signed up to certain tiers get access to exclusive content
      </div>
      <div className={classes.asterisk} id="asterisk-charity">
        <b>†</b> Approximately 10% has been donated to British Red Cross since the Patreon started (as of Jun 2022)
      </div>
    </div>
  );
};

export default LoginPage;
