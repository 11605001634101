import React from 'react';

import useStyles, { GridStyleValues } from './Grid.styles';
import StandardTile from './StandardTile';
import AlbumTile from './AlbumTile';
import { clx } from '../../utils/styleUtils';
import { SmpDataAlbum } from '../../types/SmpData';

export enum TILE_PATTERN {
  LINEAR = 'linear',
  MIXED = 'mixed',
}
export enum TILE_TYPE {
  STANDARD = 'standard',
  ALBUM = 'album',
}

interface Props {
  data: any[];
  album?: SmpDataAlbum; // parent of photo
  imgProp: string;
  lockedProp: string;
  pathBase: string;
  tileType?: TILE_TYPE;
  maxCols?: number;
}

const Grid = ({ data, album, imgProp, lockedProp, pathBase, tileType, maxCols }: Props) => {
  const styleValues: GridStyleValues = {
    maxCols: maxCols || 5,
  };
  const classes = useStyles(styleValues);

  const Tile = {
    [TILE_TYPE.STANDARD]: StandardTile,
    [TILE_TYPE.ALBUM]: AlbumTile,
  }[tileType || TILE_TYPE.STANDARD];

  return (
    <div className={clx(classes.container, tileType === TILE_TYPE.ALBUM && classes.mixed)}>
      {data.map(item => (
        <Tile key={item.id} item={item} album={album} imgProp={imgProp} pathBase={pathBase} isLocked={item[lockedProp]} />
      ))}
    </div>
  );
};

export default React.memo(Grid);
