import React, { memo, useContext } from 'react';
import { Link } from 'react-router-dom';

import { SmpDataAlbum } from '../../types/SmpData';
import { clx } from '../../utils/styleUtils';
import PatreonIcon from '../../components/icons/PatreonIcon';
import DataContext from '../../data/DataContext';
import useStyles from './styles';

interface Props {
  album: SmpDataAlbum;
  page: string;
  type: 'album' | 'photo';
  className?: string;
}

const PatreonLink = ({ album, page, type, className }: Props) => {
  const classes = useStyles();
  const { data } = useContext(DataContext);

  const hasBlurredContent = !!album.photos.find(photo => photo.blurred);
  const showUpselling = data && (!data.token_info || data.token_info.showUpselling);

  return (
    <>
      {showUpselling && hasBlurredContent && (
        <div className={clx(classes.patreon, classes.infoBox, className)}>
          <Link to="/login" className={classes.anchor}>
            <div>
              <PatreonIcon />
            </div>
            <div>Unlock this {type} with Patreon!</div>
          </Link>
        </div>
      )}
    </>
  );
};

export default memo(PatreonLink);
