import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Grid, { TILE_TYPE } from '../../components/Grid/Grid';
import DataContext from '../../data/DataContext';
import { displayDate } from '../../utils/dateUtils';

import InstagramIcon from '@material-ui/icons/Instagram';
import PublicIcon from '@material-ui/icons/Public';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import useStyles from './styles';
import { clx } from '../../utils/styleUtils';
import PhotoViewer from '../../components/PhotoViewer/PhotoViewer';
import ScrollToTopOnMount from '../RootPage/ScrollToTopOnMount';
import Anchor from '../../components/Anchor/Anchor';
import PatreonLink from '../../components/PatreonLink/PatreonLink';
import { useTitle } from '../../components/useTitle/useTitle';
import { getOrderedList } from '../../utils/photoUtils';
import { getAlbumRoute } from '../../utils/routes';
import AlbumTile from '../../components/Grid/AlbumTile';
import { IndexedDataPhoto } from '../../types/Modified';

interface AlbumParams {
  albumName: string;
  photoIndex?: string;
}

const getAlbumDate = (album, classes) => {
  let main = displayDate(album.shootDate, true);
  let posted = null;
  if (album.postDate && album.postDate !== album.shootDate) {
    posted = ` (posted ${displayDate(album.postDate)})`;
  }
  return (
    <>
      {main}
      {posted && <span className={classes.posted}>{posted}</span>}
    </>
  );
};

const getNotice = album => {
  const opening = 'Not publically listed: ';
  if (!album.enabled) {
    return opening + 'not enabled';
  }
  if (!album.postDate) {
    return opening + 'no postDate';
  }
  if (Number(new Date(album.postDate)) > Date.now()) {
    return opening + `before post date (${album.postDate})`;
  }

  return null;
};

const AlbumPage = () => {
  const classes = useStyles();

  const { albumName, photoIndex } = useParams<AlbumParams>();
  const { data, getAlbum, getUrlBase } = useContext(DataContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [albumName]);

  const album = getAlbum(albumName);

  const photos: IndexedDataPhoto[] =
    album?.photos.map((photo, index) => ({
      ...photo,
      albumIndex: index,
    })) || [];

  const orderedPhotos = album?.exclusive ? photos : (getOrderedList(photos) as IndexedDataPhoto[]);
  // const orderedPhotos = album?.photos ? album.photos : [];
  const isPhotoShown = photoIndex !== undefined;

  const photo = isPhotoShown && photos[Math.max(0, Math.min(Number(photoIndex), orderedPhotos.length - 1))];

  const titleDisplay = album && (album.model_name || album.title);
  const dateDisplay = album && getAlbumDate(album, classes);
  const showDates = !data || !data.token_info || data.token_info.showDates;

  useTitle(titleDisplay);

  const notice = album && getNotice(album);

  const sisterAlbums = album
    ? data.albums?.filter(al => al.id !== album.id && ((al.model_name && al.model_name === album.model_name) || (al.model_instagram && al.model_instagram === album.model_instagram)))
    : [];

  return (
    <div className={classes.root}>
      <ScrollToTopOnMount />
      {album && (
        <>
          <div className={classes.albumInfo}>
            {titleDisplay && <div className={clx(classes.title, classes.infoBox)}>{titleDisplay}</div>}
            <div className={classes.links}>
              {album.model_instagram && (
                <div className={clx(classes.instagram, classes.infoBox)}>
                  <Anchor href={`https://www.instagram.com/${album.model_instagram}/`} type="instagram" page={albumName}>
                    <InstagramIcon />
                    &nbsp;{album.model_instagram}
                  </Anchor>
                </div>
              )}
              {album.model_email && (
                <div className={clx(classes.email, classes.infoBox)}>
                  <Anchor href={`mailto:${album.model_email}`} type="email" page={albumName}>
                    <MailOutlineIcon />
                    &nbsp;{album.model_email}
                  </Anchor>
                </div>
              )}
              {album.model_website && (
                <div className={clx(classes.link, classes.infoBox)}>
                  <Anchor href={album.model_website} type="website" page={albumName}>
                    <PublicIcon />
                    &nbsp;{album.model_website_name || 'Website'}
                  </Anchor>
                </div>
              )}
              {album.description && <div className={clx(classes.description, classes.infoBox)}>{album.description}</div>}
            </div>
            {!album.exclusive && <PatreonLink album={album} page={albumName} type="album" />}
            {showDates && album.shootDate && <div className={clx(classes.date, classes.infoBox)}>{dateDisplay}</div>}
            {notice && <div className={classes.notice}>{notice}</div>}
          </div>
          {album && <Grid data={orderedPhotos} album={album} imgProp="src_thumb" lockedProp="blurred" pathBase={getUrlBase()} tileType={TILE_TYPE.STANDARD} maxCols={3} />}
          {!!sisterAlbums.length && (
            <div className={clx(classes.sisterAlbums)}>
              <div className={clx(classes.title, classes.infoBox)}>Other shoots with {album.model_name}</div>

              <div className={clx(classes.sisterAlbumsGrid)}>
                {sisterAlbums.map(alb => (
                  <AlbumTile key={alb.id} item={alb} imgProp="thumb_src" pathBase={getUrlBase()} isLocked={false} route={getAlbumRoute(alb)}></AlbumTile>
                ))}
              </div>
            </div>
          )}

          {isPhotoShown && <PhotoViewer album={album} photos={orderedPhotos} photo={photo} />}
        </>
      )}
    </div>
  );
};

export default AlbumPage;
