import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PatreonIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0, 0, 600, 576">
      <path d="M384.489,0.221 C265.413,0.221 168.535,97.187 168.535,216.38 C168.535,335.213 265.413,431.892 384.489,431.892 C503.197,431.892 599.771,335.213 599.771,216.38 C599.771,97.187 503.197,0.221 384.489,0.221" />
      <path d="M-0,0.219 L105.441,0.219 L105.441,575.94 L-0,575.94 z" />
    </SvgIcon>
  );
};

export default PatreonIcon;
