import React from 'react';
import { NavLink as RouterNavLink, Link as RouterLink, useLocation } from 'react-router-dom';
import { filterSearch } from '../../utils/params';

export const Link = ({ children, to, ...props }) => {
  const { search } = useLocation();

  return (
    <RouterLink to={to + filterSearch(search)} {...props}>
      {children}
    </RouterLink>
  );
};

export const NavLink = ({ children, to, ...props }) => {
  const { search } = useLocation();

  return (
    <RouterNavLink to={to + filterSearch(search)} {...props}>
      {children}
    </RouterNavLink>
  );
};
