import { makeStyles } from '../../utils/makeStyles';

const useStyles = makeStyles(() => ({
  root: {
    fontSize: '1.3rem',
    maxWidth: 500,
    margin: '0 auto',
    textAlign: 'center',
  },
  title: {
    marginBottom: '1rem',
  },
  list: {
    marginTop: '1rem',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
  },
  li: {
    width: '12em',
    fontSize: '1.3em',
    textAlign: 'left',
    lineHeight: '1.2em',
    marginBottom: '0.25em',
    whiteSpace: 'nowrap',

    '& > *': {
      fontSize: '1.25em',
    },
  },
  insta: {
    '&:hover': {
      color: 'var(--instagram)',
    },
  },
  email: {
    '&:hover': {
      color: 'var(--email)',
    },
  },
}));

export default useStyles;
