import React, { useEffect, useState } from 'react';
import { getDataFromServer } from '../services/data';
import { SmpData, SmpDataAlbum, SmpDataPhoto, getEmptySmpData } from '../types/SmpData';

interface DataContextOutput {
  data: SmpData;
  isLoaded: boolean;
  isFailed: boolean;
  getFilteredAlbums(filter?: boolean, sort?: boolean): SmpDataAlbum[];
  getAlbum(albumName: string): SmpDataAlbum | undefined;
  getPhoto(albumName: string, photoIndex: number): SmpDataPhoto | undefined;
  getUrlBase(): string;
  reset(): Promise<any>;
}

const initialState: any = getEmptySmpData();
const initialOutput: DataContextOutput = {
  data: initialState,
  isLoaded: false,
  isFailed: false,
  getFilteredAlbums: (): any => {},
  getAlbum: (): any => {},
  getPhoto: (): any => {},
  getUrlBase: (): any => {},
  reset: (): any => {},
};

const DataContext = React.createContext<DataContextOutput>(initialOutput);

const { Provider, Consumer: DataConsumer } = DataContext;

const DataProvider = ({ children }) => {
  const [data, setDataState] = useState<SmpData>(initialState);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        if (isLoaded) return;
        const newData = await getDataFromServer();
        setDataState(newData);
        setIsLoaded(true);
      } catch (err) {
        setIsFailed(true);
      }
    })();
  }, [isLoaded, setDataState]);

  // util
  const filterPhotosInAlbum = (album: SmpDataAlbum): SmpDataAlbum => ({
    ...album,
    photos: album.photos.filter(photo => photo.enabled),
  });

  // filter and sort albums;
  const getFilteredAlbums = (filter: boolean = true, sort: boolean = true) => {
    let { albums } = data;

    if (filter) {
      albums = albums.filter(({ enabled }) => enabled).filter(({ postDate }) => Number(new Date(postDate)) <= Date.now());
    }

    if (sort) {
      albums = albums.sort((a, b) => Number(new Date(b.shootDate)) - Number(new Date(a.shootDate)));
    }

    if (filter) {
      albums = albums.map(filterPhotosInAlbum);
    }

    return albums;
  };

  const getAlbum = (albumName: string, filter: boolean = true): SmpDataAlbum | undefined => {
    const album = data.albums.find(album => album.name === albumName);

    if (album && filter) {
      return filterPhotosInAlbum(album);
    }
    return album;
  };

  const getPhoto = (albumName: string, photoIndex: number): SmpDataPhoto | undefined => {
    const album = getAlbum(albumName);
    return album && album.photos[Math.max(0, Math.min(photoIndex, album.photos.length - 1))];
  };

  const getUrlBase = () => data.base_live;

  const reset = async () => {
    try {
      const newData = await getDataFromServer();
      setDataState(newData);
    } catch (err) {}
  };

  const output: DataContextOutput = {
    data,
    isLoaded,
    isFailed,
    getFilteredAlbums,
    getAlbum,
    getPhoto,
    getUrlBase,
    reset,
  };

  return <Provider value={output}>{children}</Provider>;
};

export default DataContext;

export { DataProvider, DataConsumer };
