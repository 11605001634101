export const displayDate = (input: string, forceYear: boolean = false) => {
  const when = new Date(input);

  const isThisYear = new Date().getFullYear() === when.getFullYear();

  const options = {
    day: 'numeric',
    month: 'long',
    year: isThisYear && !forceYear ? undefined : 'numeric'
  };

  return when.toLocaleDateString(undefined, options);
};
